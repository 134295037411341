import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Paid from './Paid';
import ErrorPaid from './ErrorPaid';
import GenericError from  './GenericError';
import  { ShowPaymentExternal } from '../../../api/end.points';

const ExternalBuys = (props:any) => {
	let data = new URLSearchParams(useLocation().search);
	const [info, setInfo] : any = useState(null);
	const Payment = async () => {
		const token = data.get("webpay_token_ws") ?? data.get("token")
		let detail=null;
		if(token){
			detail= await ShowPaymentExternal(token);
		}
		setInfo(detail)
	}
	useEffect(() => {
		if (info==null) {
			Payment();
		}
	});
	if (info==null) {
		return(
			<div className="container">
				<h3 className="py-5 text-center">
					Cargando detalle de pago..
				</h3>
			</div>
		);
	}

	if(data.get("status") === "no_found" || info.status==='no_found'){
		return <GenericError info={info} TitleDescriptionError="PAGO NO ENCONTRADO" InformationError="Por favor validar la información." />
	}
	if(data.get("status") === "not_processed"){
		return <GenericError info={info} TitleDescriptionError="PAGO NO PROCESADO" InformationError="Algo salió mal" />
	}
	let return_result:any;

	switch ( info.data.status_txt ) {
		case "finished":
			return_result = <GenericError info={info} TitleDescriptionError="ANULASTE EL PAGO" InformationError="Ha Cancelado el pago." />
			break;
		case "paid":
			return_result = <Paid  info={info}/>
			break;
		case "error":
			return_result = <ErrorPaid info={info}/>
			break;
		default:
			return_result = 
				<h5 className="text-center py-5">
					Estado "<strong>{data.get("status")}</strong>" desconocido
					<br/>
					codigo de pago: {data.get("webpay_token_ws")}
					<br/>
					Copia el codigo y comunicate con el administrador del sitio!
				</h5>
			
			break;
	}
	return return_result;
};
export default ExternalBuys;