export const types = {
    login: '[Auth] login',
    logout: '[Auth] logout',

    set_search_producto : 'set_search_producto',
    get_producto_list : 'get_producto_list',
    get_producto_detalle : 'get_producto_detalle',
    get_producto_category_list : 'get_producto_category_list',
    set_filtro_producto : 'set_filtro_producto',
    claer_filtro_producto : 'claer_filtro_producto',

    get_receta_list : 'get_receta_list',
    set_filtro_receta : 'set_filtro_receta',
    get_receta_detalle : 'get_receta_detalle',
    search_receta : 'search_receta',
    get_receta_category_list : 'get_receta_category_list',
    get_producto_category_detalle : 'get_producto_category_detalle',
    claer_filtro_receta : 'claer_filtro_receta',
    
    get_cart : 'get_cart',
    add_to_cart : 'add_to_cart',
    subtract_to_cart : 'subtract_to_cart',
    remove_from_cart : 'remove_from_cart',
    total_items : 'total_items',

    new_order : 'new_order',
    clear_order : 'clear_order',

    set_open_sidebar_cart : 'set_open_sidebar_cart',

    // Profile
    set_profile_personal : 'set_profile_personal',
    set_profile_phones : 'set_profile_phones',
    set_profile_addresses : 'set_profile_addresses',
    set_profile_order : 'set_profile_order',
    
    remove_all_items_cart: 'remove_all_items_cart',

    set_profile_delivery_type: 'set_profile_delivery_type',
    set_profile_document_type: 'set_profile_document_type',
    set_profile_payment_type: 'set_profile_payment_type',

    get_search_list: 'get_search_list',
    set_search_item: 'set_search_item',
    set_loading: 'set_loading',
    cart_update_amounts: 'cart_update_amounts',

    set_profile_authenticate : 'set_profile_authenticate',
    set_new_order_dispatch: 'set_new_order_dispatch',
    set_new_order_document_ticket: 'set_new_order_document_ticket',
    set_new_order_document_bill: 'set_new_order_document_bill',
    set_new_order_dispatch_home: 'set_new_order_dispatch_home',
    set_new_order_dispatch_adress_region: 'set_new_order_dispatch_adress_region',
    set_new_order_dispatch_adress_commune: 'set_new_order_dispatch_adress_commune',
    set_new_order_dispatch_adress_home_street: 'set_new_order_dispatch_adress_home_street',
    set_new_order_dispatch_adress_home_reset: 'set_new_order_dispatch_adress_home_reset',
    set_new_order_document_type: 'set_new_order_document_type',
    set_new_order_delivery_type: 'set_new_order_delivery_type',
    set_new_order_payment: 'set_new_order_payment',
    set_new_order_sucursal_id: 'set_new_order_sucursal_id',
    set_cart_update_shipping: 'set_cart_update_shipping',
    set_cart_update_total_shipping: 'set_cart_update_total_shipping',
    set_cart_update_selected_shipping: 'set_cart_update_selected_shipping',
    set_new_order_shipping_id : 'set_new_order_shipping_id',
    set_search_close : 'set_search_close',
    set_search_close_initial : 'set_search_close_initial',
    set_visible_alert: 'set_visible_alert',
    set_no_visible_alert: 'set_no_visible_alert',
    set_new_order_dispatch_home_new_adress: 'set_new_order_dispatch_home_new_adress',

    set_shopsearch_categories_list: 'set_shopsearch_categories_list',
    set_shopsearch_categories_add: 'set_shopsearch_categories_add',
    set_shopsearch_categories_remove: 'set_shopsearch_categories_remove',
    set_shopsearch_subcategories_sel_add: 'set_shopsearch_subcategories_sel_add',
    set_shopsearch_subcategories_sel_remove: 'set_shopsearch_subcategories_sel_remove',
    set_shopsearch_subcategories_no_sel_remove: 'set_shopsearch_subcategories_no_sel_remove',
    set_shopsearch_list: 'set_shopsearch_list',
    set_shopsearch_default: 'set_shopsearch_default',
    set_loading_receta: 'set_loading_receta'

};
