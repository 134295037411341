import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    categorias: [],
    categoria : {}
};

export const productoCategoryReducer = (state = initialState, action: Actions) => {
    const {type, payload} = action;
    
    switch (type) {
        case types.get_producto_category_list: 
            const { categorias } = payload;
            return {
                ...state,
                categorias
            }

        case types.get_producto_category_detalle: 
            const { categoria } = payload
            return {
                ...state,
                categoria
            }
        
        default:
            return state;
    }
}