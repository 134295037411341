import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    loading: false,  
    categories: [],
    categoriessel: [],
    subcategories:[],
    subcategoriessel:[],
    subcategoriesnosel:[],
    productos: [],
    meta: []
};


export const ShopSearchReducer = (state = initialState, action: Actions) => {
    const { type, payload  } = action;

    switch (type) {
        case types.set_shopsearch_categories_list: 
            const { categories } = payload;

            return {
                ...state,
                categories
            }

        case types.set_shopsearch_categories_add:
            const { categoriessel , subcategories , subcategoriesnosel } = payload;
            return {
                ...state,
                categoriessel , 
                subcategories , subcategoriesnosel
            }

        case types.set_shopsearch_categories_remove:
            
            return {
                ...state,
                categoriessel : payload.categoriessel , 
                subcategories : payload.subcategories , 
                subcategoriesnosel : payload.subcategoriesnosel
            }

        case types.set_shopsearch_subcategories_sel_add:

            return {
                ...state,
                subcategoriesnosel: payload.subcategoriesnosel,
                subcategoriessel: payload.subcategoriessel
            }

        case types.set_shopsearch_subcategories_sel_remove:

                return {
                    ...state,
                    subcategoriesnosel: payload.subcategoriesnosel,
                    subcategoriessel: payload.subcategoriessel
                }
        
        case types.set_shopsearch_subcategories_no_sel_remove:
            return {
                ...state,
                subcategoriesnosel: payload.subcategoriesnosel
            }   

        case types.set_shopsearch_list:
            return {
                ...state,
                productos: payload.productos,
                meta: payload.meta,
                loading: false
            }
        
        case types.set_shopsearch_default:
            return {
                    ...state,
                    loading: false,
                    subcategories: payload.subcategories,
                    subcategoriessel: payload.subcategories,
                    subcategoriesnosel: payload.subcategoriesnosel
            }

        default:
            return state;
    }
}
