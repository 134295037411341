import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    search : null,
    items : [],
    item : {},
    filtros : {},
    items_searched : [],
    meta : {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: null,
        per_page: 6,
        to: null,
        total: null
    },
    loading : true,
    who_called: '',
    closesearch: false
};

export const searchReducer = (state = initialState, action: Actions) => {
    const { type, payload  } = action;

    switch (type) {
        case types.get_search_list:
            return {
                ...state,
                items : payload.items,
                meta : payload.meta,
                loading : false,
                who_called: payload.who_called
            }  
        case types.set_search_item:
                const { search, items_searched , who_called } = payload
    
                return {
                    ...state,
                    items_searched,
                    search,
                    who_called
                }       
        case types.set_loading:
            const {loading} = payload;
            return {
                ...state,
                loading
            } 
         
        case types.set_search_close:

            /* state.search = payload.search;
            state.items_searched = payload.items_searched; */
            state.closesearch = payload.closesearch;

            return {
                ...state
            }     
        
        case types.set_search_close_initial:
            state.closesearch = false;
            return {
                ...state
            }     
        
        default:
            return state;
    }
}