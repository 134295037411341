import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import AssetsImg from '../../../components/Img/index';
import { GeneratePaymentExternal } from '../../../api/end.points';
import GenericError from  './GenericError';

const GenerateLink = (props: any) => {

    let data = new URLSearchParams(useLocation().search);
    const [info, setInfo]: any = useState({status: "" , message : "" });

    const GenerateLink = async () => {
        const token = data.get("token_ws") ?? ''
        let detail = null;
        if (token) {

            detail = await GeneratePaymentExternal(token);
            
            if(detail.status === 'success') {
                window.location.href = detail.link;
            } else {
                if(detail.status === 'paid' ) {
                    info.status = detail.status; 
                    info.message = detail.message;
                }
            }
        }

        setInfo(detail)
    }
    useEffect(() => {
        if (info.status == "") {
            GenerateLink();
        }
    });
    if (info.status == "") {
        return (<>
            <>
                <div className="container__404">
                    <div className="container__404__group">
                        <p className="lbl__title_404">Procesando . . .</p>
                        <p className="lbl__title_description_404"></p>
                        <img className="img__404" src={AssetsImg.IconLogoThermomix} alt="Página No Encontrada" />
                        <p className="lbl__information__404"> . . .</p>
                    </div>
                </div>

            </>
        </>)
    } else if(info.status  === 'paid' )  {
        return <GenericError info={info} TitleDescriptionError="PAGO YA FUE REALIZADO" InformationError={info.message} /> 
    }
}

export default GenerateLink;