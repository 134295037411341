import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/main.scss';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-MK8JZNM'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
