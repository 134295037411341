import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    categorias: [],
    filtros : {}
};

export const recetaCategoryReducer = (state = initialState, action: Actions) => {
    const {type, payload} = action;
    
    switch (type) {
        case types.get_receta_category_list: 
            return {
                ...state,
                categorias : payload,
                loading : false
            }
        case types.set_filtro_receta:
            const { filtros } = payload
            return {
                ...state,
                filtros : {
                    ...state.filtros,
                    ...filtros
                },
                loading : false,
            }
        case types.claer_filtro_receta:
            return {
                ...state,
                filtros : {}
            }
        default:
            return state;
    }
}