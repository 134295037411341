import hookup from './hookup.axios';
import Processing from './lumen.form';

const linkApi=process.env.REACT_APP_ENV_API_URL;
const linkDomain=process.env.REACT_APP_ENV_DOMAIN_URL;

export const LumenForm = new Processing();

// Auth
export const SingIn = async ( data:any ) => await hookup('post','/auth/sing/in',data);
export const SingUp = async ( data:any ) => await hookup('post','/auth/sing/up',data);
export const SingInFaccebook = `${linkApi}/auth/facebook`;

//Recovery & change pass
export const RecoveryPass = async ( data:any ) => await hookup('post','/auth/recovery/password',data);
export const ChangePass = async ( data:any ) => await hookup('post','/auth/change/password',data);
export const UpdatePass = async ( data:any ) => await hookup('put','/auth/change/rpassword',data);
export const RecoveryUsername = async ( data:any ) => await hookup('post','/auth/recovery/username',data);

// Profile
export const Profile = async ( data:any ) => await hookup('get','/auth/profile',data);
export const ProfileUpdate = async ( data:any ) => await hookup('put','/auth/profile',data);

export const CreatePhone = async ( data:any ) => await hookup('post','/auth/phone',data);
export const UpdatePhone = async ( data:any, id:number) => await hookup('put',`/auth/phone/${id}`,data);
export const DeletePhone = async ( data:any, id:number) => await hookup('delete',`/auth/phone/${id}`,data);

export const CreateAddress = async ( data:any ) => await hookup('post','/auth/address',data);
export const UpdateAddress = async ( data:any, id:number) => await hookup('put',`/auth/address/${id}`,data);
export const DeleteAddress = async ( data:any, id:number) => await hookup('delete',`/auth/address/${id}`,data);

// Options
export const ListRegions = async () => await hookup('get','/auth/regions',{});
export const ListCommunes = async () => await hookup('get','/auth/communes',{});

// Shop
export const UpdateCart = async ( data:any ) => await hookup('post','/shop/shopping/cart',data);
export const Categories = async ( data:any = {} ) => await hookup('get','/shop/categories',data);
export const CategoryDetails = async ( slug : string) => await hookup('get',`/shop/categories/${slug}`, {});
//export const Products = async ( data:any ) => await hookup('get','/shop/product/list',data);
export const ProductsSearch = async ( data:any ) => await hookup('get','/shop/product/list',data);
export const Products = async ( data:any ) => await hookup('get','/shop/product/listnew',data);
export const ProductDetails = async ( value:string , param='slug' ) => await hookup('get',`/shop/product/${param}/${value}`,{});

// Payment
export const CreateOrder = async ( data:any ) => await hookup('post','/payment/create/order',data);
export const ShowPayment = async ( token:any ) => await hookup('get',`/payment/show/${token}`,{});
export const GetShipping = async ( data:any ) => await hookup('post','/payment/get/shipping',data);

export const ShowPaymentExternal = async ( token:any ) => await hookup('get',`/paymentExternal/show/${token}`,{});
export const GeneratePaymentExternal = async ( token:any ) => await hookup('post',`/payment/paymentExternal/generate/${token}`,{});


// Forms
export const FormTechnicalService = async ( data:any ) => await hookup('post','/google/technical/service',data);
export const FormContact = async ( data:any ) => await hookup('post','/google/contact',data);
export const FormScheduleYourDemo = async ( data:any ) => await hookup('post','/google/schedule/your/demo',data);
export const FormJoinUp = async ( data:any ) => await hookup('post','/google/join/up',data);
export const FormNewsLetter = async ( data:any ) => await hookup('post','/google/news/letter',data);
//export const ProductEvaluation = async ( data:any ) => await hookup('post','/shop/product/evaluation',data);

export const ProductEvaluation = async ( data:any ) => await hookup('post','/product-detail/comment',data);

// sucursales

export const ListBranches = async () => await hookup('get','/branches',{});
export const ListCommunesByRegion = async (id:string) => await hookup('get',`/communes/${id}`, {});
export const ListAdressByGoogle = async (search:string) => await hookup('get',`/search/address/autocomplete/${search}`, {});
export const CreateNewOrder = async ( data:any ) => await hookup('post','/payment/pagonew/create/order',data);
export const ListOrders = async () => await hookup('get','/MyBuys',{});

export const DonwloadRecetario = (slug:string) => `${linkApi}/download/recetario/${slug}`;
export const FileDomain = (name:string) => `${linkDomain}${name}`;

export const ListCommunesZoho = async () => await hookup('get','/communes/zoho/list',{});