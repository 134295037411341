import axios from 'axios';
import {InfoSession} from '../session';

const linkApi=process.env.REACT_APP_ENV_API_URL

const encodeQueryString = (filters:any) => {
    let url = '';
    Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
            filters[key].map((item:any) => {
                if (item !== null) {
                    url += `${key}=${item}&`;
                }
                return true;
                // url += this.encodeQueryStringFromArray(key, item, index) + '&'
            });
        } else if (filters[key]) {
            url += `${key}=${filters[key]}&`;
        }
    });
    return url.substring(0, url.length - 1);
}

const hookup = async (method:any,url:string,data:object) => {
	let mainResponse = {
        data:[],
		validations:null,
        unauthorized:false,
	};
	const query =encodeQueryString(data);
	url = method==='get' ? `${url}?${query}` : url ;
	let config= {
	  method: method,
	  baseURL: linkApi,
	  url: url,
	  data: data,
	  headers: {'Authorization': InfoSession().authorization}
	}
	mainResponse = await axios(config)
	.then(function (response) {
    return response.data
  })
  .catch(function (error) {
    console.info(
    	`Ups! ocurrio un error toma un capture y reportalo: \n\n[${method}] ${linkApi}${url}\n\n${error}`
    );
    const response = error.response
    //console.log(error.response.status)
    if (response.status === 422) {
        mainResponse.validations=response.data;
        return mainResponse;
    }
    if (response.status === 401 || response.status === 404) {
        mainResponse.unauthorized=true;
        mainResponse.data=response.data;
        return mainResponse;
    }
    return mainResponse
  });
  //console.log('LOG HOOKUP',mainResponse)
  return mainResponse
}
export default hookup;