
import React from 'react';
import HelperCoin from '../../../services/currency'
import Breadcrumb from '../../../components/partials/BreadCrumb';

const ErrorPaid = (prosp:any) => {

	const getItems = () =>{
        return[
                { 'id': 1 , 'active':'0','location':'/','name':'Inicio'  } ,
                { 'id': 2 , 'active':'0','location':'/carro-de-compras','name':'Carro de Compra'  } ,
                { 'id': 3 ,'active':'1','location':'/checkout','name':'Compra WebPay'  } 
            ]
	}
	console.log(prosp.info);
	return(
		<div className="container container--no__fluid"  style={{marginBottom: '30px'}}>
            

			<div className="container bg-white pb-5">
			<h1 className="py-5 text-center">
				Error en el pago ;(
			</h1>
			<div className="row">
				<div className="col-md-12">
					<p>
						Hola, <strong>{prosp.info.data?.input_fullname}</strong>
					</p>
						<strong>Nº PAGO </strong>: XJD-VYZ-XRT{prosp.info.data?.id}
						<br/>
						<strong>Correo Electrónico </strong>: {prosp.info.data?.input_email}
						<br/>
						<strong>Token</strong>:{prosp.info.data?.token}
						<br />
						<strong>Ws Token</strong>:{prosp.info.data?.webpay_token_ws}
						<br />
						<strong>Error WebPay</strong>: {prosp.info.data?.webpay_response_code}
						<br />
						<strong>FECHA</strong>: {prosp.info.data.webpay_transaction_date}
						<br/>
						<strong>TOTAL</strong>: <span className="text-success">{HelperCoin.format(prosp.info.data.input_amount)}</span>
						<br/>
						<strong>TARJETA</strong>:&bull;&bull;&bull;&bull;&bull;&bull;&bull;{prosp.info.data.webpay_card_number}
						<br/>
						<strong>WEBPAY DICE</strong>: {prosp.info.data.webpay_response_description}
				</div>
			</div>
		</div>
		</div>
	);
}
export default ErrorPaid