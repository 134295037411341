
import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    search : null,
    productos : [],
    producto : {},
    filtros : {},
    productos_searched : [],
    meta : {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: null,
        per_page: 6,
        to: null,
        total: null
    },
    loading : true
};

export const productoReducer = (state = initialState, action: Actions) => {
    const { type, payload } = action;

    switch (type) {
        case types.get_producto_list:
            return {
                ...state,
                productos : payload.productos,
                meta : payload.meta,
                loading : false
            }
        case types.get_producto_detalle:
            const {producto} = payload
            return {
                ...state,
                producto
            }
            break;
        case types.set_filtro_producto:
            const { filtros } = payload
            return {
                ...state,
                filtros : {
                    ...state.filtros,
                    ...filtros
                }
            }
        case types.set_search_producto:
            const { search, productos_searched } = payload

            return {
                ...state,
                productos_searched,
                search
            }
        case types.claer_filtro_receta:
            return {
                ...state,
                filtros : {}
            }
        default:
            return state;
    }
}