import { types } from "../types";

const initialState : any = {
    recetas : [],
    pagination : {
        first_page : 1,
        current_page : 1,
        per_page : null,
        next_page : 2,
        total_rows : null,
        total_pages : null,
        last_page : 1
    },
    receta_carousel : [],
    receta :  null,
    recetas_similares : [],
    loading : true,
    error : {},
};

export const recetaReducer = (state = initialState, action: any) => {
    const {type, payload} = action;

    switch (type) {
        case types.get_receta_list:        
            const { recetas, pagination } = payload

            return {
                ...state,
                recetas,
                pagination : { 
                    ...state.pagination,
                    ...pagination,
                    last_page : pagination.total_pages
                },
                loading : false
            }
        case types.get_receta_detalle:
            const { receta , recetas_similares } = payload
            return {
                ...state,
                receta,
                recetas_similares,
                loading : false
            }
        
        case  type.set_loading_receta:
            const { loading } = payload
            console.log('setemaddpppp')
            return {
                ...state,
                loading
            }   

        default:
            return state;
    }
}