import  icon_tienda from '../../assets/img/svg/tienda.svg';
import  icon_domicilio from '../../assets/img/svg/domicilio.svg'
import  icon_tienda_blanco from '../../assets/img/svg/tienda_blanco.svg';
import  icon_domicilio_blanco from '../../assets/img/svg/domicilio_blanco.svg'
import  icon_cruz from '../../assets/img/svg/cruz.svg'
import  icon_carritox from '../../assets/img/svg/carritoX.svg'
import  icon_logo from '../../assets/img/png/logo@2x.png'
import icon_webpay from '../../assets/img/png/webpay.png'
import icon_separator from '../../assets/img/svg/separator.svg'
import icon_cupon from '../../assets/img/svg/cupon.svg'
import icon_flecha_white from '../../assets/img/svg/flecha_white.svg'
import icon_flecha_return from '../../assets/img/svg/flecha_volver.svg'

import icon_money_white from '../../assets/img/svg/peso_blanco.svg'
import icon_money_green from '../../assets/img/svg/peso_verde.svg'

import icon_data_white from '../../assets/img/svg/datos_blanco.svg'
import icon_data_green from '../../assets/img/svg/datos_verde.svg'

import icon_dispatch_white from '../../assets/img/svg/despacho_blanco.svg'
import icon_dispatch_green from '../../assets/img/svg/despacho_verde.svg'
import icon_search from '../../assets/img/svg/Enmascarar grupo 301.svg'
import icon_chief from '../../assets/img/svg/Enmascarar grupo 228-1.svg'
import icon_magnifying_glass from '../../assets/img/svg/lupa.svg'
import icon_magnifyin_mobile from "../../assets/img/svg/Enmascarar grupo 301.svg";

import icon_email from  "../../assets/img/svg/confirmacion_email.svg";
import icon_404 from  "../../assets/img/svg/404.svg";

import icon_slider_1 from '../../assets/img/svg/slider/bannerprincipal.svg';

import icon_goTo_shop from '../../assets/img/svg/home/goto_shop.png';
import icon_goTo_shop_mobile from '../../assets/img/svg/home/goto_shop_mobile.svg';
import icon_goTo_shop_mobile_degradado from '../../assets/img/svg/home/goto_shop_mobile_degradado.png'; 
import icon_goTo_recetario from '../../assets/img/svg/home/goto_recetario.svg';
import icon_goTo_recetario_mobile from '../../assets/img/svg/home/goto_recetario_mobile.svg';
import icon_goTo_demo from '../../assets/img/svg/home/goto_demo.svg';
import icon_goTo_demo_mobile from '../../assets/img/svg/home/goto_demo_mobile.svg';
import icon_arrow_green from '../../assets/img/svg/home/arrow_green.svg';

import icon_home_video from '../../assets/img/svg/home/homevideo.svg';
import icon_home_video_mobile from '../../assets/img/svg/home/homevideo_mobile.svg';
import icon_play from '../../assets/img/svg/home/play.svg';
import url_tm6 from '../../assets/files/video/video-tm6.mp4'
import icon_home_demo from '../../assets/img/svg/home/homedemo.svg';


import icon_tiempo from '../../assets/img/svg/home/beneficios/tiempo.svg';
import icon_tiempo_mobile from '../../assets/img/svg/home/beneficios/tiempo_mobile.svg';
import icon_dinero from '../../assets/img/svg/home/beneficios/dinero.svg';
import icon_dinero_mobile from '../../assets/img/svg/home/beneficios/dinero_mobile.svg';
import icon_comebien from '../../assets/img/svg/home/beneficios/comebien.svg'; 
import icon_comebien_mobile from '../../assets/img/svg/home/beneficios/comebien_mobile.svg'; 
import icon_cocinafacil from '../../assets/img/svg/home/beneficios/cocinafacil.svg';
import icon_cocinafacil_mobile from '../../assets/img/svg/home/beneficios/cocinafacil_mobile.svg';
import icon_secreativo from  '../../assets/img/svg/home/beneficios/secreativo.svg';
import icon_secreativo_mobile from  '../../assets/img/svg/home/beneficios/secreativo_mobile.svg';

import icon_instagram from  '../../assets/img/svg/home/instagram.png';

import LogoFacebook from  '../../assets/img/svg/home/facebook.svg';
import LogoFacebookMobile from  '../../assets/img/svg/home/facebook_mobile.svg';
import LogoFacebookBlueMobile from  '../../assets/img/svg/profile/facebook_mobile_blue.svg';
import LogoInstagram from  '../../assets/img/svg/home/instagram.svg';
import LogoInstagramMobile from  '../../assets/img/svg/home/instagram_mobile.svg';
import LogoYoutube from  '../../assets/img/svg/home/youtube.svg';
import LogoYoutubeMobile from  '../../assets/img/svg/home/youtube_mobile.svg';

import LogoFema from  '../../assets/img/svg/home/logofema.svg';
import LogoThermomix from  '../../assets/img/svg/home/logothermomix.svg';
import LogoVorwerk from  '../../assets/img/svg/home/logovorwerk.png';

import icon_reset_pass from  '../../assets/img/svg/profile/resetpass.svg';
import icon_aplausos from  '../../assets/img/svg/profile/aplausos.svg';

import icon_ubicacion  from '../../assets/img/svg/profile/ubicacion.svg';
import icon_ubicacion_white  from '../../assets/img/svg/profile/ubicacion_white.svg';
import icon_carrocompra  from '../../assets/img/svg/profile/carrocompra.svg';
import icon_carrocompra_white  from '../../assets/img/svg/profile/carrocompra_white.svg';
import icon_cerrarsesion  from '../../assets/img/svg/profile/cerrarsesion.svg';
import icon_cerrarsesion_white  from '../../assets/img/svg/profile/cerrarsesion_white.svg';
import icon_userprofile  from '../../assets/img/svg/profile/user.svg';
import icon_userprofile_white  from '../../assets/img/svg/profile/user_white.svg';
import icon_userprofile_gris  from '../../assets/img/svg/profile/mis_datoss_gris.svg';
import icon_userprofile_green  from '../../assets/img/svg/profile/misdatosverde.svg';

import icon_lateral_adress from '../../assets/img/svg/adress/lateraldirecciones.svg';
import icon_adress_home from  '../../assets/img/svg/adress/casa.svg';
import icon_adress_job from  '../../assets/img/svg/adress/trabajo.svg';
import icon_adress_other from  '../../assets/img/svg/adress/location.svg';
import icon_arrow_right  from  '../../assets/img/svg/adress/flecha_right.svg'; 
import icon_arrow_down  from  '../../assets/img/svg/adress/flecha_down.svg';
import icon_trash from  '../../assets/img/svg/adress/delete.svg'; 
import icon_edit from   '../../assets/img/svg/adress/editar.svg';
import icon_lateral_buys from '../../assets/img/svg/buys/lateralbuys.svg'; 
import icon_send_success from '../../assets/img/svg/home/send_success.svg'; 
import icon_send_error from '../../assets/img/svg/home/send_error.svg';
import icon_shop_thermomix from '../../assets/img/svg/shop/thermomix.svg'; 
import icon_shop_thermomix_mobile from '../../assets/img/svg/shop/thermomix_2x.svg';
import icon_category_accesorios from  '../../assets/img/png/category/accesorios.png';
import icon_category_libros_chips from  '../../assets/img/png/category/libros_y_chips.png';
import icon_category_repuestos from  '../../assets/img/png/category/repuestos.png';
import icon_category_thermomix_tm6 from  '../../assets/img/png/category/thermomix_tm6.png';
import icon_shop_image_mobile from  '../../assets/img/svg/shop/imagentienda_2x.png'; 
import icon_star_white from  '../../assets/img/svg/shopdetail/star_white.svg';
import icon_star_green from  '../../assets/img/svg/shopdetail/star_green.svg'; 
import icon_star_gris from  '../../assets/img/svg/shopdetail/star_gris.svg';
import icon_cruz_negra from  '../../assets/img/svg/shopdetail/cruznegra.svg';
import icon_commentary from  '../../assets/img/svg/shopdetail/comentario.svg';
import icon_history_back from '../../assets/img/svg/shopdetail/flecha_volver.svg';
import icon_stars_white_mobile from '../../assets/img/svg/shopdetail/estrellas_blancas_mobile.svg';
import icon_add from '../../assets/img/svg/shop/add.svg'; 
import icon_shop_bannertienda  from '../../assets/img/svg/shop/bannertienda.png';
import icon_shop_filtro_mobile  from '../../assets/img/svg/shop/filtro.svg';
import icon_arrow_next from '../../assets/img/svg/slider/arrow_next.svg'; 
import icon_arrow_preview from '../../assets/img/svg/slider/arrow_preview.svg';
import icon_tm6_1  from '../../assets/img/svg/tm6/tm6_1.svg';
import icon_tm6_2  from '../../assets/img/svg/tm6/tm6_2.svg';
import icon_tm6_3  from '../../assets/img/svg/tm6/tm6_3.svg';
import icon_tm6_4  from '../../assets/img/svg/tm6/tm6_4.svg';
import icon_tm6_5  from '../../assets/img/svg/tm6/tm6_5.png';
import icon_tm6_6  from '../../assets/img/svg/tm6/tm6_6.svg';
import icon_tm6_7  from '../../assets/img/svg/tm6/tm6_7.svg';
import icon_tm6_8  from '../../assets/img/svg/tm6/tm6_8.png';
import icon_tm6_9  from '../../assets/img/svg/tm6/tm6_9.svg';
import icon_tm6_10  from '../../assets/img/svg/tm6/tm6_10.svg';

import icon_prod_top_1  from '../../assets/img/svg/top/top_prod_1.png'; 
import icon_prod_top_2  from '../../assets/img/svg/top/top_prod_2.png'; 
import icon_prod_top_3  from '../../assets/img/svg/top/top_prod_3.png'; 
import icon_prod_top_4  from '../../assets/img/svg/top/top_prod_4.png'; 

import icon_receta_detalle_timer from '../../assets/img/svg/recetadetalle/timer.svg';
import icon_receta_detalle_thermomix from '../../assets/img/svg/recetadetalle/thermomix.svg';
import icon_receta_detalle_preparation from '../../assets/img/svg/recetadetalle/preparation.svg';
import icon_receta_detalle_dish from '../../assets/img/svg/recetadetalle/dish.svg';
import icon_receta_detalle_chef from '../../assets/img/svg/recetadetalle/chef.svg'; 
import icon_banner_ayuda from '../../assets/img/svg/ayuda/bannerayuda.jpg'; 

import icon_help_branches  from '../../assets/img/svg/ayuda/sucursales.jpg';
import icon_help_contact  from '../../assets/img/svg/ayuda/contacto.jpg';
import icon_help_guide  from '../../assets/img/svg/ayuda/guide.jpg';
import icon_help_technical  from '../../assets/img/svg/ayuda/technical.jpg';

import icon_arrow_goback_white from '../../assets/img/svg/sucursales/arrowwhiteback.svg'; 

import icon_sucursal_vitacura from '../../assets/img/svg/sucursales/vitacura.jpg';
import icon_sucursal_chicureo from '../../assets/img/svg/sucursales/chicureo.jpg';
import icon_sucursal_condes from '../../assets/img/svg/sucursales/condes.jpg';
import icon_sucursal_serena from '../../assets/img/svg/sucursales/serena.jpg';
import icon_sucursal_technical from '../../assets/img/svg/sucursales/technical.jpg';

import icon_sucursal_vitacura_mobile from '../../assets/img/svg/sucursales/vitacura_mobile.jpg';
import icon_sucursal_chicureo_mobile from '../../assets/img/svg/sucursales/chicureo_mobile.jpg';
import icon_sucursal_condes_mobile from '../../assets/img/svg/sucursales/condes_mobile.jpg';
import icon_sucursal_serena_mobile from '../../assets/img/svg/sucursales/serena_mobile.jpg';
import icon_sucursal_technical_mobile from '../../assets/img/svg/sucursales/technical_mobile.jpg';

import icon_sucursal_pointer from '../../assets/img/svg/sucursales/pointer.svg';
import icon_sucursal_plus from '../../assets/img/svg/sucursales/mas.svg';
import icon_sucursal_pointer_mobile from '../../assets/img/svg/sucursales/pointer_mobile.svg';
import icon_manuales_download from '../../assets/img/svg/manuales/download.svg'; 
import icon_manuales_youtube from '../../assets/img/svg/manuales/youtube.svg'; 
import icon_manuales_download_white from '../../assets/img/svg/manuales/downloadwhite.svg';

import file_tm6 from  "../../assets/files/Manual_Thermomix-TM6_USA-Canada-Mexico.pdf";
import file_tm5 from  "../../assets/files/20700_SN_GA_TM5_ES_V2.pdf";
import file_tm31 from "../../assets/files/SN_23291_Vorwerk_GA_TM31_ES_160921.pdf";
import file_cooki_tm6 from "../../assets/files/cookidoo_2.0-brochure-first-steps.pdf";
import file_cooki_tm5 from "../../assets/files/Manual Paso a paso Cook-key (para clientes tm5).pdf";

import icon_flecha_negra_tutorial from "../../assets/img/svg/flechanegra.svg";

import icon_unete_extras from "../../assets/img/svg/unete/UneteExtras.svg";
import icon_unete_equipo from "../../assets/img/svg/unete/UneteEquipo.svg";
import icon_unete_formacion_continua from "../../assets/img/svg/unete/UneteFormacionContinua.svg";
import icon_unete_tiempo from "../../assets/img/svg/unete/UneteTiempo.svg";
import icon_unete_banner from "../../assets/img/svg/unete/bannerunete.jpg";

import icon_unete_extras_mobile from "../../assets/img/svg/unete/UneteExtrasMobile.svg";
import icon_unete_equipo_mobile from "../../assets/img/svg/unete/UneteEquipoMobile.svg";
import icon_unete_formacion_continua_mobile from "../../assets/img/svg/unete/UneteFormacionContinuaMobile.svg";
import icon_unete_tiempo_mobile from "../../assets/img/svg/unete/UneteFormacionContinuaMobile.svg";

import icon_demo_c1  from "../../assets/img/svg/tudemo/c1.jpg";
import icon_demo_c1_mobile  from "../../assets/img/svg/tudemo/c1_mobile.png";
import icon_demo_c2  from "../../assets/img/svg/tudemo/c2.png";
import icon_demo_c2_mobile  from "../../assets/img/svg/tudemo/c2_mobile.png";
import icon_demo_c3  from "../../assets/img/svg/tudemo/c3.jpg";
import icon_demo_c3_mobile  from "../../assets/img/svg/tudemo/c3_mobile.png";
import icon_demo_c4  from "../../assets/img/svg/tudemo/c4.png";
import icon_demo_c4_mobile  from "../../assets/img/svg/tudemo/c4_mobile.png";
import icon_demo_c5  from "../../assets/img/svg/tudemo/c5.png";
import icon_demo_c5_mobile  from "../../assets/img/svg/tudemo/c5_mobile.png";
import icon_demo_c6  from "../../assets/img/svg/tudemo/c6.png";
import icon_demo_c6_mobile  from "../../assets/img/svg/tudemo/c6_mobile.png";
import icon_demo_plus  from "../../assets/img/svg/tudemo/masverde.svg";
import icon_tech_banner from  "../../assets/img/svg/technical/bannertechnical.jpg";
import icon_recetariosearch from  "../../assets/img/svg/technical/bannertechnical.jpg";

import icon_recetario_categoria_panaderia  from  "../../assets/img/svg/recetario/categoria/panaderia.svg";
import icon_recetario_categoria_panaderia_checked  from  "../../assets/img/svg/recetario/categoria/panaderia_checked.svg";
import icon_recetario_categoria_carnesyaves  from  "../../assets/img/svg/recetario/categoria/carnesyaves.svg";
import icon_recetario_categoria_carnesyaves_checked  from  "../../assets/img/svg/recetario/categoria/carnesyaves_checked.svg";
import icon_recetario_categoria_principalesyotros  from  "../../assets/img/svg/recetario/categoria/principalesyotros.svg";
import icon_recetario_categoria_principalesyotros_checked  from  "../../assets/img/svg/recetario/categoria/principalesyotros_checked.svg";
import icon_recetario_categoria_postres  from  "../../assets/img/svg/recetario/categoria/postres.svg";
import icon_recetario_categoria_postres_checked  from  "../../assets/img/svg/recetario/categoria/postres_checked.svg";
import icon_recetario_categoria_pastasarrocesy  from  "../../assets/img/svg/recetario/categoria/pastasarrocesy.svg";
import icon_recetario_categoria_pastasarrocesy_checked  from  "../../assets/img/svg/recetario/categoria/pastasarrocesy_checked.svg";


import icon_recetario_categoria_entrantesyaperitivos  from  "../../assets/img/svg/recetario/categoria/entrantesyaperitivos.svg";
import icon_recetario_categoria_entrantesyaperitivos_checked  from  "../../assets/img/svg/recetario/categoria/entrantesyaperitivos_checked.svg";
import icon_recetario_categoria_paratomar  from  "../../assets/img/svg/recetario/categoria/paratomar.svg";
import icon_recetario_categoria_paratomar_checked  from  "../../assets/img/svg/recetario/categoria/paratomar_checked.svg";
import icon_recetario_categoria_reposteria  from  "../../assets/img/svg/recetario/categoria/reposteria.svg";
import icon_recetario_categoria_reposteria_checked  from  "../../assets/img/svg/recetario/categoria/reposteria_checked.svg";
import icon_recetario_categoria_acompanamientos  from  "../../assets/img/svg/recetario/categoria/acompanamientos.svg";
import icon_recetario_categoria_acompanamientos_checked  from  "../../assets/img/svg/recetario/categoria/acompanamientos_checked.svg";
import icon_recetario_categoria_basicos  from  "../../assets/img/svg/recetario/categoria/basicos.svg";
import icon_recetario_categoria_basicos_checked  from  "../../assets/img/svg/recetario/categoria/basicos_checked.svg";


import icon_recetario_categoria_desayunosbrunch  from  "../../assets/img/svg/recetario/categoria/desayunosbrunch.svg";
import icon_recetario_categoria_desayunosbrunch_checked  from  "../../assets/img/svg/recetario/categoria/desayunosbrunch_checked.svg";
import icon_recetario_categoria_ninos  from  "../../assets/img/svg/recetario/categoria/ninos.svg";
import icon_recetario_categoria_ninos_checked  from  "../../assets/img/svg/recetario/categoria/ninos_checked.svg";
import icon_recetario_categoria_ensaladasyaderezos  from  "../../assets/img/svg/recetario/categoria/ensaladasyaderezos.svg";
import icon_recetario_categoria_ensaladasyaderezos_checked  from  "../../assets/img/svg/recetario/categoria/ensaladasyaderezos_checked.svg";
import icon_recetario_categoria_sopasycremas  from  "../../assets/img/svg/recetario/categoria/sopasycremas.svg";
import icon_recetario_categoria_sopasycremas_checked  from  "../../assets/img/svg/recetario/categoria/sopasycremas_checked.svg";
import icon_recetario_categoria_pescadosymariscos  from  "../../assets/img/svg/recetario/categoria/pescadosymariscos.svg";
import icon_recetario_categoria_pescadosymariscos_checked  from  "../../assets/img/svg/recetario/categoria/pescadosymariscos_checked.svg";


import icon_recetario_categoria_salsas  from  "../../assets/img/svg/recetario/categoria/salsas.svg";
import icon_recetario_categoria_salsas_checked  from  "../../assets/img/svg/recetario/categoria/salsas_checked.svg";
import icon_recetario_categoria_vegetarianos  from  "../../assets/img/svg/recetario/categoria/vegetarianos.svg";
import icon_recetario_categoria_vegetarianos_checked  from  "../../assets/img/svg/recetario/categoria/vegetarianos_checked.svg";
import icon_recetario_categoria_trucos  from  "../../assets/img/svg/recetario/categoria/trucos.svg";
import icon_recetario_categoria_trucos_checked  from  "../../assets/img/svg/recetario/categoria/trucos_checked.svg";
import icon_recetario_categoria_navidad  from  "../../assets/img/svg/recetario/categoria/navidad.svg";
import icon_recetario_categoria_navidad_checked  from  "../../assets/img/svg/recetario/categoria/navidad_checked.svg";

import icon_recetario_lupa  from  "../../assets/img/svg/recetario/lupa.svg";
import icon_recetario_lupa_mobile  from  "../../assets/img/svg/recetario/lupa_mobile.svg";

import icon_pagination_preview from  "../../assets/img/svg/pagination/preview.svg";
import icon_pagination_next from  "../../assets/img/svg/pagination/next.svg";

import icon_banner_cookido from  "../../assets/img/svg/recetario/cookido.png";
import icon_banner_cookido_mobile from  "../../assets/img/svg/recetario/cookido_mobile.png";

const AssetsImg = {
	IconDomicilio: icon_domicilio,
	IconTienda: icon_tienda,
	IconDomicilioBlanco: icon_domicilio_blanco,
	IconTiendaBlanco: icon_tienda_blanco,
	IconCruz: icon_cruz,
	IconCarritoX: icon_carritox,
	IconLogo: icon_logo,
	IconWebPay: icon_webpay,
	IconSeparator: icon_separator,
	IconCupon: icon_cupon,
	IconFlechaWhite: icon_flecha_white,
	IconFlechaReturn: icon_flecha_return,
	IconDispatchWhite: icon_dispatch_white,
	IconDispatchGreen: icon_dispatch_green,
	IconDataWhite: icon_data_white,
	IconDataGreen: icon_data_green,
	IconMoneyWhite: icon_money_white,
	IconMonenyGreen: icon_money_green,
	IconSearchHeader: icon_search,
	IconChiefHeader: icon_chief,
	IconMagnifyingGlass: icon_magnifying_glass,
	IconMagnifyingGlassMobile : icon_magnifyin_mobile,
	IconEmail: icon_email,
	Icon404 : icon_404,
	IconSlider1 : icon_slider_1,
	IconGoToShop : icon_goTo_shop,
	IconGoToDemo: icon_goTo_demo,
	IconGoToRecetario: icon_goTo_recetario,
	IconArrowGreen: icon_arrow_green,
	IconHomeVideo: icon_home_video,
	IconPlay: icon_play,
	UrlTm6: url_tm6 ,
	IconHomeDemo: icon_home_demo,
	IconAhorraTiempo: icon_tiempo,
	IconAhorraDinero: icon_dinero,
	IconComeBien: icon_comebien,
	IconCocinaFacil: icon_cocinafacil,
	IconSeCreativo: icon_secreativo,
	IconHomeInstragram: icon_instagram,
	IconFacebook: LogoFacebook, 
	IconInstagram: LogoInstagram,
	IconYoutube: LogoYoutube,
	IconLogoFema: LogoFema, 
	IconLogoThermomix : LogoThermomix ,
	IconLogoVorWerk: LogoVorwerk,
	IconGoToShopMobile: icon_goTo_shop_mobile,
	IconGoToShopMobileDegradado: icon_goTo_shop_mobile_degradado,
	IconGoToDemoMobile: icon_goTo_demo_mobile,
	IconGoToRecetarioMobile: icon_goTo_recetario_mobile,
	IconHomeVideoMobile: icon_home_video_mobile, 
	IconAhorraTiempoMobile: icon_tiempo_mobile,
	IconAhorraDineroMobile: icon_dinero_mobile,
	IconComeBienMobile: icon_comebien_mobile,
	IconCocinaFacilMobile: icon_cocinafacil_mobile,
	IconSeCreativoMobile :icon_secreativo_mobile,
	IconFacebookMobile : LogoFacebookMobile,
	IconInstagramMobile:  LogoInstagramMobile,
	IconYouTubeMobile: LogoYoutubeMobile,
	IconLogoFacebookBlueMobile: LogoFacebookBlueMobile,
	IconResetPass: icon_reset_pass,
	IconAplausos: icon_aplausos,
	IconUbicacion: icon_ubicacion,
	IconUbicacionWhite: icon_ubicacion_white,
	IconUbicacionGris: icon_userprofile_gris,
	IconUbicacionGreen: icon_userprofile_green,
	IconMisPedidos: icon_carrocompra,
	IconMisPedidosWhite: icon_carrocompra_white,
	IconCerrarSesion: icon_cerrarsesion, 
	IconCerrarSesionWhite: icon_cerrarsesion_white,
	IconUserProfile: icon_userprofile,
	IconUserProfileWhite: icon_userprofile_white,
	IconLateralAdress: icon_lateral_adress,
	IconAdressHome: icon_adress_home ,
 	IconAdressJob: icon_adress_job,
	IconAdressOther: icon_adress_other,
	IconArrowRight: icon_arrow_right, 
	IconArrowDown: icon_arrow_down,
	IconTrash: icon_trash,
	IconEdit: icon_edit,
	IconLateralBuys: icon_lateral_buys,
	IconSendSuccess: icon_send_success, 
	IconSendError: icon_send_error,
	IconShopBannerThermomix: icon_shop_thermomix,
	IconCategoryAccesorios: icon_category_accesorios,
	IconCategoryLibros: icon_category_libros_chips,
	IconCategoryRepuestos: icon_category_repuestos, 
	IconCategoryThermomixTM6: icon_category_thermomix_tm6,
	IconShopThermomixMobile: icon_shop_thermomix_mobile,
	IconShopImageMobile: icon_shop_image_mobile,
	IconStarWhite: icon_star_white,
	IconStarGreen: icon_star_green,
	IconStarGris: icon_star_gris,
	IconCruzNegra: icon_cruz_negra,
	IconComentario: icon_commentary,
	IconArrowHistoryBack: icon_history_back,
	IconStarWhiteMobile: icon_stars_white_mobile,
	IconAdd: icon_add,
	IconShopBannerTienda: icon_shop_bannertienda,
	IconShopFiltroMobile: icon_shop_filtro_mobile,
	IconArrowNext: icon_arrow_next,
	IconArrowPreview: icon_arrow_preview,

	IconThermomixA1: icon_tm6_1,
	IconThermomixA2: icon_tm6_2,
	IconThermomixA3: icon_tm6_3,
	IconThermomixA4: icon_tm6_4,
	IconThermomixA5: icon_tm6_5,
	IconThermomixA6: icon_tm6_6,
	IconThermomixA7: icon_tm6_7,
	IconThermomixA8: icon_tm6_8,
	IconThermomixA9: icon_tm6_9,
	IconThermomixA10: icon_tm6_10,
	IconProdTop1: icon_prod_top_1,   
	IconProdTop2: icon_prod_top_2,   
	IconProdTop3: icon_prod_top_3,  
	IconProdTop4: icon_prod_top_4,

	IconRecetaDetalleTimer: icon_receta_detalle_timer ,
	IconRecetaDetalleThermomix: icon_receta_detalle_thermomix ,
	IconRecetaDetallePreparation: icon_receta_detalle_preparation ,
	IconRecetaDetalleDish: icon_receta_detalle_dish ,
	IconRecetaDetalleChef: icon_receta_detalle_chef ,
	IconBannerAyuda: icon_banner_ayuda,
	IconHelpBranches: icon_help_branches,
	IconHelpContact: icon_help_contact,
	IconHelpGuide: icon_help_guide,
	IconHelpTechnical: icon_help_technical ,
	IconArrowGoBackWhite : icon_arrow_goback_white,
	IconSucursalVitacura : icon_sucursal_vitacura,
	IconSucursalChicureo : icon_sucursal_chicureo, 
	IconSucursalCondes :  icon_sucursal_condes, 
	IconSucursalSerena :  icon_sucursal_serena, 
	IconSucursalTechnical :  icon_sucursal_technical,
	IconSucursalPointer: icon_sucursal_pointer,
	IconSucursalPlus: icon_sucursal_plus,
	IconSucursalPointerMobile: icon_sucursal_pointer_mobile,

	IconSucursalVitacuraMobile : icon_sucursal_vitacura_mobile,
	IconSucursalChicureoMobile : icon_sucursal_chicureo_mobile, 
	IconSucursalCondesMobile :  icon_sucursal_condes_mobile, 
	IconSucursalSerenaMobile :  icon_sucursal_serena_mobile, 
	IconSucursalTechnicalMobile :  icon_sucursal_technical_mobile,
	IconManualesDownload : icon_manuales_download,
	IconManualesYoutube: icon_manuales_youtube,
	IconManualesDownloadWhite: icon_manuales_download_white,
	FileManualesTM6 :  file_tm6,
	FileManualesTM5 :  file_tm5, 
	FileManualesTM31 :  file_tm31, 
	FileManualesCookiTM6 : file_cooki_tm6,  
	FileManualesCookiTM5 : file_cooki_tm5,
	IconFlechaNegraTutorial: icon_flecha_negra_tutorial ,

	IconUneteExtras : icon_unete_extras, 
	IconUneteEquipo: icon_unete_equipo,
	IconUneteFormacion: icon_unete_formacion_continua,
	IconUneteTiempo: icon_unete_tiempo,
	IconUneteBanner: icon_unete_banner,
	IconUneteExtrasMobile : icon_unete_extras_mobile, 
	IconUneteEquipoMobile: icon_unete_equipo_mobile,
	IconUneteFormacionMobile: icon_unete_formacion_continua_mobile,
	IconUneteTiempoMobile: icon_unete_tiempo_mobile,

	IconDemoC1: icon_demo_c1,
	IconDemoC2: icon_demo_c2,
	IconDemoC3: icon_demo_c3,
	IconDemoC4: icon_demo_c4,
	IconDemoC5: icon_demo_c5,
	IconDemoC6: icon_demo_c6,
	IconDemoPlus: icon_demo_plus,

	IconDemoC1Mobile: icon_demo_c1_mobile,
	IconDemoC2Mobile: icon_demo_c2_mobile,
	IconDemoC3Mobile: icon_demo_c3_mobile,
	IconDemoC4Mobile: icon_demo_c4_mobile,
	IconDemoC5Mobile: icon_demo_c5_mobile,
	IconDemoC6Mobile: icon_demo_c6_mobile,
	IconBannerTechnicalService: icon_tech_banner,
	IconRecetarioSearch: icon_recetariosearch,

	IconRecetarioCategoriaPanaderia: icon_recetario_categoria_panaderia,
	IconRecetarioCategoriaPanaderiaChecked: icon_recetario_categoria_panaderia_checked,
	IconRecetarioCategoriaCarnesyAves: icon_recetario_categoria_carnesyaves,
	IconRecetarioCategoriaCarnesyAvesChecked: icon_recetario_categoria_carnesyaves_checked,
	IconRecetarioCategoriaPrincipalesyotros: icon_recetario_categoria_principalesyotros,
	IconRecetarioCategoriaPrincipalesyotrosChecked: icon_recetario_categoria_principalesyotros_checked,
	IconRecetarioCategoriaPostres: icon_recetario_categoria_postres,
	IconRecetarioCategoriaPostresChecked: icon_recetario_categoria_postres_checked,
	IconRecetarioCategoriaPastasarrocesy: icon_recetario_categoria_pastasarrocesy,
	IconRecetarioCategoriaPastasarrocesyChecked: icon_recetario_categoria_pastasarrocesy_checked,

	IconRecetarioCategoriaEntrantesyaperitivos: icon_recetario_categoria_entrantesyaperitivos,
	IconRecetarioCategoriaEntrantesyaperitivosChecked: icon_recetario_categoria_entrantesyaperitivos_checked,
	IconRecetarioCategoriaParatomar: icon_recetario_categoria_paratomar,
	IconRecetarioCategoriaParatomarChecked: icon_recetario_categoria_paratomar_checked,
	IconRecetarioCategoriaReposteria: icon_recetario_categoria_reposteria,
	IconRecetarioCategoriaReposteriaChecked: icon_recetario_categoria_reposteria_checked,
	IconRecetarioCategoriaAcompanamientos: icon_recetario_categoria_acompanamientos,
	IconRecetarioCategoriaAcompanamientosChecked: icon_recetario_categoria_acompanamientos_checked,
	IconRecetarioCategoriaBasicos: icon_recetario_categoria_basicos,
	IconRecetarioCategoriaBasicosChecked: icon_recetario_categoria_basicos_checked,
	IconRecetarioCategoriaDesayunosbrunch: icon_recetario_categoria_desayunosbrunch,
	IconRecetarioCategoriaDesayunosbrunchChecked: icon_recetario_categoria_desayunosbrunch_checked,
	IconRecetarioCategoriaNinos: icon_recetario_categoria_ninos,
	IconRecetarioCategoriaNinosChecked: icon_recetario_categoria_ninos_checked,
	IconRecetarioCategoriaEnsaladasyaderezos: icon_recetario_categoria_ensaladasyaderezos,
	IconRecetarioCategoriaEnsaladasyaderezosChecked: icon_recetario_categoria_ensaladasyaderezos_checked,
	IconRecetarioCategoriaSopasycremas: icon_recetario_categoria_sopasycremas,
	IconRecetarioCategoriaSopasycremasChecked: icon_recetario_categoria_sopasycremas_checked,
	
	IconRecetarioCategoriaPescadosymariscos: icon_recetario_categoria_pescadosymariscos,
	IconRecetarioCategoriaPescadosymariscosChecked: icon_recetario_categoria_pescadosymariscos_checked,
	IconRecetarioCategoriaSalsas: icon_recetario_categoria_salsas,
	IconRecetarioCategoriaSalsasChecked: icon_recetario_categoria_salsas_checked,
	IconRecetarioCategoriaVegetarianos: icon_recetario_categoria_vegetarianos,
	IconRecetarioCategoriaVegetarianosChecked: icon_recetario_categoria_vegetarianos_checked,
	IconRecetarioCategoriaTrucos: icon_recetario_categoria_trucos,
	IconRecetarioCategoriaTrucosChecked: icon_recetario_categoria_trucos_checked,
	IconRecetarioCategoriaNavidad: icon_recetario_categoria_navidad,
	IconRecetarioCategoriaNavidadChecked: icon_recetario_categoria_navidad_checked,
	IconRecetarioLupa: icon_recetario_lupa,
	IconPaginationPreview: icon_pagination_preview,
	IconPaginationNext: icon_pagination_next,
	IconBannerCookido: icon_banner_cookido,
	IconRecetarioLupaMobile: icon_recetario_lupa_mobile,
	IconBannerCookidoMobile: icon_banner_cookido_mobile

}

export default AssetsImg;