import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

export const sidebarCartReducer = (state = false, action: Actions) => {
    const {type, payload} = action;
    
    switch (type) {
        case types.set_open_sidebar_cart:
            return payload;
            
        default:
            return state;
    }
}