
import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

import { SaveNewOrder, GetNewOrder } from '../../api/session';

const defaultState = {
    email: ( localStorage.getItem('email') || '' )  ,
    name_presenter: '',
    order_comments: '',
    shipping_id: 0,
    deliveryType: {
        delivery_type: 0,
        home_street: '',
        dpto_home_office_aditional: '',
        home_optional_aditional_information: '',
        home_new_adress: 0,
        store_id_sucursal: 0,
        address_id: 0,
        region_id: 0,
        commune_id: 0
    },
    paymentType: {
        payment_type: 0,
        term_and_conditions: 0,
    },
    documentType: {
        document_type: 0,
        ticket_name: (localStorage.getItem('full_name') || ''),
        ticket_rut: '',
        ticket_phone: '',
        bill_name: '',
        bill_rut: '',
        bill_phone: '',
        bill_giro: '',
        bill_address_dispatch: 0,
        bill_street: '',
        bill_pick_up_name: '',
        bill_pick_up_phone: '',
    }
}


const initialState: any = GetNewOrder() || defaultState;

export const OrderNewReducer = (state = initialState, action: Actions) => {
    const { type, payload } = action;

    switch (type) {

        case types.set_new_order_dispatch_home:
            state.deliveryType = payload.deliverytype;
            SaveNewOrder(state);
            return {
                ...state,
            }

        case types.set_new_order_dispatch:
            state.email = payload.email.email
            SaveNewOrder(state);
            return {
                ...state,
            }
        case types.set_new_order_dispatch_adress_region :
            state.deliveryType.region_id = payload.region_id;
            SaveNewOrder(state);
            return {
                ...state,
            }
        case types.set_new_order_dispatch_adress_commune: 
            state.deliveryType.commune_id = payload.commune_id;
            SaveNewOrder(state);
            return {
                ...state,
            }
        
        case types.set_new_order_dispatch_adress_home_street:
            state.deliveryType.home_street = payload.home_street;    
            SaveNewOrder(state);
            return {
                ...state,
            }

        case types.set_new_order_dispatch_adress_home_reset:
            state.deliveryType.home_street = payload.home_street;
            state.deliveryType.commune_id =   payload.commune_id;
            state.deliveryType.region_id = payload.region_id;
            state.deliveryType.dpto_home_office_aditional = payload.dpto_home_office_aditional;
            SaveNewOrder(state);
            return {
                ...state,
            }

        case types.set_new_order_document_ticket:
            
            state.documentType.ticket_name = payload.DocumentTypeTicket.ticket_name;
            state.documentType.ticket_rut = payload.DocumentTypeTicket.ticket_rut;
            state.documentType.ticket_phone = payload.DocumentTypeTicket.ticket_phone;
            state.documentType.document_type =  payload.DocumentTypeTicket.document_type;
            SaveNewOrder(state);
            return {
                ...state,
            }

        case types.set_new_order_document_bill:

            state.documentType.document_type =  payload.DocumentTypeBill.document_type;
            state.documentType.bill_name =  payload.DocumentTypeBill.bill_name;
            state.documentType.bill_rut =  payload.DocumentTypeBill.bill_rut;
            state.documentType.bill_phone =  payload.DocumentTypeBill.bill_phone;
            state.documentType.bill_giro =  payload.DocumentTypeBill.bill_giro;
            state.documentType.bill_address_dispatch =  payload.DocumentTypeBill.bill_address_dispatch;
            state.documentType.bill_street =  payload.DocumentTypeBill.bill_street;
            state.documentType.bill_pick_up_name =  payload.DocumentTypeBill.bill_pick_up_name;
            state.documentType.bill_pick_up_phone =  payload.DocumentTypeBill.bill_pick_up_phone;
            
            SaveNewOrder(state);
            return {
                ...state,
            }
        
        case types.set_new_order_document_type:
            state.documentType.document_type =  payload.document_type;
            SaveNewOrder(state);
            return {
                ...state,
            }
        
        case  types.set_new_order_delivery_type:
            state.deliveryType.delivery_type =  payload.delivery_type;
            SaveNewOrder(state);
            return {
                ...state,
            }   


        case types.set_new_order_payment:
            state.paymentType.payment_type = payload.paymentType.payment_type;
            state.paymentType.term_and_conditions = payload.paymentType.term_and_conditions;    
            SaveNewOrder(state);
            return {
                ...state,
            }  
        
        case types.set_new_order_sucursal_id:
            state.deliveryType.store_id_sucursal = payload.store_id_sucursal;
            SaveNewOrder(state);
            return {
                ...state,
            }

        case types.set_new_order_shipping_id:
            state.shipping_id = payload.shipping_id;
            SaveNewOrder(state);
            return {
                ...state,
            }   

        case types.set_new_order_dispatch_home_new_adress:
            state.deliveryType.home_new_adress = payload.home_new_adress
            SaveNewOrder(state);
            return {
                ...state,
            } 
        default:
            return state;
    }
}
