import { ILink } from "../interfaces/utils.interface";

import icon1 from "../assets/images/svg/receta_new.svg"; // recetas
import icon2 from "../assets/images/svg/Enmascarar grupo 237.svg"; // Demo
import icon3 from "../assets/images/svg/Enmascarar grupo 239.svg"; // Tienda 
import icon4 from "../assets/images/svg/Enmascarar grupo 228.svg"; // unete
import icon5 from "../assets/images/svg/Enmascarar grupo 297.svg"; // help


export const menuLinks : ILink[]  = [
    {
        path : '/recetas', 
        name : 'Recetario', 
        name2 : 'Recetario', 
        icon : icon1
    },
    {
        path : '/agenda-tu-demo', 
        name : 'Agenda tu Demo',
        name2 : 'Demo',
        icon : icon2
    },
    {
        path : '/tienda', 
        name : 'Tienda',
        name2 : 'Tienda',
        icon : icon3
    },
    {
        path : '/unete', 
        name : 'Únete',
        name2 : 'Únete',
        icon : icon4
    },
    {
        path : '/ayuda', 
        name : 'Ayuda',
        name2 : 'Ayuda',
        icon : icon5
    }
]