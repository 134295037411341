import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import { store } from './redux/store';
import { AppRouter } from './routes/AppRouter';

import  './assets/scss/main.scss';
/* import MetaTag from './components/theme/MetaTag'; */


export const App = () => {

    useEffect(() =>{});

    return (
        <>
        {/* <MetaTag /> */}
        <Provider store={store}>
            <AppRouter />
        </Provider>
        </>
    );
};
