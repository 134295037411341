import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import View404 from '../pages/View404';
import Footer from '../components/theme/Footer';
import FooterMenu from '../components/partials/FooterMenu';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import ExternalBuys from '../pages/New/ExternalBuys/ExternalBuys';
import GenerateLink from '../pages/New/ExternalBuys/GenerateLink';

export const AppRouter = () => {

    return (
        <>
            <Router>
                <div id="outer-container">

                <div id="page-wrap" className="px-3"  style={{marginLeft: '-1px',overflowX: 'hidden'}}>
                    
                    <Switch>

                        <Route exact path="/details/external/buys" component={ExternalBuys} />
                        <Route exact path={`/details/external/generate`} component={GenerateLink} />

                        <Route>
                          <View404/>
                        </Route>
                    </Switch>

                </div>
                <Footer />
                <FooterMenu />
            </div>
            </Router>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              />
        </>
    );
};
