import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";
import { SaveProfile, GetProfile } from '../../api/session';

const defaultState = {

    personal: {
        email: '',
        name: '',
        last_name: '',
        rut: '',
        username:'',
        fullname: ''
    },
    phones: [
        {
            id: null,
            code: null,
            number: '',
            type: 0,
            main_default: 1,
        }
    ],
    addresses: [
        {
            id: null,
            street: '',
            number: '',
            department_number: '',
            region_id: '',
            commune_id: '',
            main_default: 1,
            type: 0
        }
    ]
    /* ,
    order: {
        name_presenter: '',
        order_comments: '',
    } */
    
};
const initialState: any = GetProfile() || defaultState;

export const profileReducer = (state = initialState, action: Actions) => {
    const { type, payload } = action;

    switch (type) {
        case types.set_profile_personal:
            state.personal = payload.personal;
            SaveProfile(state);
            return {
                ...state,
            }
        case types.set_profile_phones:
            state.phones = payload.phones;
            SaveProfile(state);
            return {
                ...state,
            }
        case types.set_profile_addresses:
            state.addresses = payload.addresses;
            SaveProfile(state);
            return {
                ...state,
            }
        case types.set_profile_order:
            state.order = payload.order;
            SaveProfile(state);
            return {
                ...state,
            }

        case types.set_profile_delivery_type:
            state.deliveryType = payload.deliverytype;
            SaveProfile(state);
            return {
                ...state,
            }
        
        case types.set_profile_document_type:
                state.documentType = payload.documenttype;
                SaveProfile(state);
                return {
                    ...state,
                }
        
        case types.set_profile_payment_type:
                state.paymentType = payload.paymentype;
                SaveProfile(state);
                return {
                    ...state,
                }
        
        case types.set_profile_authenticate:
                
            state.personal = payload.personal;
            state.phones = payload.phones;
            state.addresses = payload.addresses;    
            SaveProfile(state);

            return {
                ...state,
            }

        default:
            return state;
    }
}