import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { authReducer } from './reducers/authReducer';
import thunk from 'redux-thunk';
import { productoReducer } from './reducers/productoReducer';
import { recetaReducer } from './reducers/recetaReducer';
import { orderReducer } from './reducers/orderReducer';
import { cartReducer } from './reducers/cartReducer';
import { sidebarCartReducer } from './reducers/sidebarCartReducer';
import { recetaCategoryReducer } from './reducers/recetaCategoryReducer';
import { recetaSearchReducer } from './reducers/recetaSearchReducer';
import { profileReducer } from './reducers/profileReducer';
import { productoCategoryReducer } from './reducers/productoCategoryReducer';
import { searchReducer } from './reducers/searchReducer';
import {OrderNewReducer} from './reducers/OrderNewReducer';
import {AlertReducer} from './reducers/AlertReducer';
import {ShopSearchReducer} from './reducers/ShopSearchReducer';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: authReducer,
    producto : productoReducer,
    productoCategory : productoCategoryReducer,
    receta : recetaReducer,
    order : orderReducer,
    cart : cartReducer,
    sidebarCart : sidebarCartReducer,
    recetaSearch : recetaSearchReducer,
    recetaCategory : recetaCategoryReducer,
    profile : profileReducer,
    search: searchReducer,
    neworder: OrderNewReducer,
    alert: AlertReducer,
    shopsearch: ShopSearchReducer,
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
