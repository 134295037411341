import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const orderReducer = (state = {}, action : Actions) => {
    const {type}  = action;
    switch (type) {
        case types.new_order:
            return { order : action.payload };

        case types.clear_order:
            return { order : null };

        default:
            return state;
  }
};
export { orderReducer };