import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState = { 
    cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
    totalItems : (localStorage.getItem("totalItems") || 0),
    subTotal : 0,
    loading : true,
    total: (localStorage.getItem("total") || 0),
    coupon_amount:(localStorage.getItem("coupon_amount") || 0),
	total_discount_items:(localStorage.getItem("total_discount_items") || 0),
	total_amount_items:(localStorage.getItem("total_amount_items") || 0),
	total_discount_coupon: (localStorage.getItem("total_discount_coupon") || 0),
    total_amount_payment: (localStorage.getItem("total_amount_payment") || 0),
    total_shipping: (localStorage.getItem("total_shipping") || 0),
    type_shipping:0,
    description_shipping: (localStorage.getItem("description_shipping") || 'Despacho a Domicilio'),
    shipping_details:  JSON.parse(localStorage.getItem("shipping_details") || '[]')
};


export const cartReducer = (state = initialState, action : Actions) => {
    const { type, payload }  = action;

    switch (type) {
        case types.add_to_cart:
            return { 
                ...state,
                ...payload
            };
        case types.remove_from_cart:
            return { 
                ...state,
                ...payload
            };
        case types.get_cart:
            return state;

        case types.subtract_to_cart:
            return { 
                ...state,
                ...payload
            };
        case types.remove_all_items_cart:
            return { 
                ...state,
                ...payload
            };   
        
        case types.cart_update_amounts:
             
             return { 
                ...state,
                ...payload
            };   

        case types.set_cart_update_shipping:
 
            state.type_shipping = payload.type_shipping;
            state.description_shipping = payload.description_shipping; 

            return {
                ...state,
            }  
        
        case types.set_cart_update_total_shipping:

            state.shipping_details = payload.shipping_details;
            
            return {
                ...state,
            }
        
        case types.set_cart_update_selected_shipping:    
            state.total_shipping = payload.total_shipping;    
            return {
                ...state,
            }
            
        default:
            return state;
    }
};

