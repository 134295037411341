import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import HelperCoin from '../../../services/currency'
import Breadcrumb from '../../../components/partials/BreadCrumb';
import AssetsImg from '../../../components/Img/index';
import { removeAllItemsToCart} from '../../../redux/actions/cartAction';
import {Profile} from '../../../api/end.points';
import { InfoSession } from '../../../api/session';
import  Actions from '../../../redux/actions';

const Paid = (prosp: any) => {

    const { cartItems } = useSelector((state: any) => state.cart)
    const dispatch = useDispatch();

    const getItems = () => {
        return [
            { 'id': 1, 'active': '0', 'location': '/', 'name': 'Inicio' },
            { 'id': 2, 'active': '0', 'location': '/carro-de-compras', 'name': 'Carro de compra' },
            { 'id': 3, 'active': '1', 'location': '/checkout', 'name': 'Compra WebPay' }
        ]

    }

    return (
        <>

            <div className="container container--no__fluid container__paid" >
                
                <div className="container__header__buys">
                    <div className="container__header__buys-image ">
                        <img src={AssetsImg.IconEmail} alt="Compra Exitosa" />
                    </div>
                    <div className="container__header__buys-description">
                        <p className="container__header__buys-description-title">Tu compra ha sido realizada correctamente</p>
                        <div className="container__header__buys-image__responsive">
                            <img src={AssetsImg.IconEmail} alt="Compra Exitosa" />
                        </div>
                        <p className="container__header__buys-description-title_details">
                            ¡Gracias por elegir <span>THERMOMIX ®</span>!
                    </p>
                    </div>
                </div>
                <div className="container__body__buys" style={{marginBottom: '30px'}}>
                    <p className="container__body__buys-title">Detalle de tu compra</p>
                    <div className="container__body__buys-details">
                        <div className="container__body__buys-information">
                            <div className="container__body__buys-information_item">
                                <p className="lbl__buys"><label className="lbl__buys__info">Número de Pedido: </label><label className="lbl__buys__info_enfasis">{prosp.info.data?.id}</label></p>
                                <p className="lbl__buys__details"><label className="lbl__buys__info">Fecha de Pago: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.webpay_transaction_date.substr(0,10)}</label></p>
                                <p className="lbl__buys"><label className="lbl__buys__info">Referencia: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.input_identificator_foreign}</label></p>
                                <p className="lbl__buys"><label className="lbl__buys__info">Extras: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.input_extras_foreign}</label></p>
                            </div>
                            <div className="container__body__buys-information_item">
                                <p className="lbl__buys"><label className="lbl__buys__info">Código de Autorización: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.webpay_authorization_code}</label></p>
                                <p className="lbl__buys__details"><label className="lbl__buys__info">Nº de cuotas: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.webpay_shares_number === 0 ? '01' : ('00000' + prosp.info.data.webpay_shares_number).substr(-2,2) }</label></p>
                                <p className="lbl__buys"><label className="lbl__buys__info">Cliente: </label><label className="lbl__buys__info_enfasis">{prosp.info.data.input_fullname}</label></p>
                            </div>
                            <div className="container__body__buys-information_item">
                                <p className="lbl__buys"><label className="lbl__buys__info">Estado: </label><label className="lbl__buys__info_enfasis">Pagado</label></p>
                                <p className="lbl__buys__details"><label className="lbl__buys__info">Nº de tarjeta: </label><label className="lbl__buys__info_enfasis">XXXX XXX {prosp.info.data.webpay_card_number}</label></p>
                                <p className="lbl__buys__details"><label className="lbl__buys__info">Monto:  </label><label className="lbl__buys__info_enfasis">{HelperCoin.format(prosp.info.data.input_amount)}</label></p>
                            </div>
                        </div>
                        <a href="https://www.thermomix.cl/" className="btn btn-primary btn-lg btn__back_to_index">Volver al Inicio</a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Paid;