import React from 'react';
import AssetsImg from '../components/Img/index'
import { Link } from 'react-router-dom';

const View404 = () =>{
		return(
			<>
				<div  className="container__404">
					<div className="container__404__group">
						<p className="lbl__title_404">ERROR 404</p>
						<p className="lbl__title_description_404">PÁGINA NO ENCONTRADA</p>
						<img className="img__404" src={AssetsImg.Icon404} alt="Página No Encontrada"/>
						<p className="lbl__information__404">Disculpa, no hemos podido encontrar la página que buscabas</p>
						<a href="https://www.thermomix.cl/" className="btn btn-primary btn-lg btn__back_to_index">Volver al Inicio</a>
					</div>
				</div>

			</>
			
		);
}
export default View404;