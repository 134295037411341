import { Actions } from "../../interfaces/redux.interface";
import { types } from "../types";

const initialState : any = {
    recetas: [],
    loading : true
};

export const recetaSearchReducer = (state = initialState, action: Actions) => {
    const {type, payload} = action;
    
    switch (type) {
        case types.search_receta: 
            return {
                recetas : payload,
                loading : false
            }

        default:
            return state;
    }
}