
import React from 'react';
import { Link } from 'react-router-dom';
import AssetsImg from '../../../components/Img/index';

const GenericError = (prosp:any) => {

    const {  TitleDescriptionError , InformationError } = prosp;

    return(
        <>
            <div  className="container__error">
                <div className="container__error_group">
                    <p className="lbl__title_description_error">{TitleDescriptionError}</p>
                    <img className="img__error" src={AssetsImg.IconCarritoX} alt="Página No Encontrada"/>
                    <p className="lbl__information__error">{InformationError}</p>
                    <a href="https://www.thermomix.cl/" className="btn btn-primary btn-lg btn__back_to_index">Volver al Inicio</a>
                </div>
            </div>
        </>
    );
}
export default GenericError