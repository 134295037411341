export const get_currency = (value:any) => {

		let dataLocal = { locale:'es-CL', currency:'CLP' };

		let amount = value === null ? 0 : value;

		amount = dataLocal.locale === 'es-CL' ? parseInt(amount) : parseFloat(amount);
		//alert(amount)
    const formatter = new Intl.NumberFormat(dataLocal.locale, {
        style: 'currency',
        currency: dataLocal.currency,
    });
    let amount_string: string= formatter.format(amount);
    // menos a los 10 mil falla y por eso se agrega esto
    amount_string = amount_string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    return amount_string;
}

export const isFloat = (n:any) => {
    return Number(n) === n && n % 1 !== 0;
}
export const getDiscount = (n:any) => {
    let data:any=(Number(n) * 100);
    data=parseInt(data)
    return `${data}% `;
}

export const calcProductDiscount = (price:any, discount:any) => {
    let discount_float = HelperCoin.isFloat(parseFloat(discount)) ? parseFloat(discount) : 0;
    let new_price = price-(price * discount_float);
    let apply_discount = discount_float > 0;
    return {
        discount_float,
        new_price,
        apply_discount,
    };
}

const HelperCoin = {
	format:get_currency,
    isFloat:isFloat,
    getDiscount:getDiscount,
    calcProductDiscount
}
export default HelperCoin;
