import React from 'react'
import { Link } from 'react-router-dom';

import { menuLinks } from '../../services/menu-link.service';

const FooterMenu = (props:any) => {

    const classNameMenu = (is_home:any) => {
        if (is_home === 1) {
           return "footer-menu shadow-up footer-menu-is-home"; 
        }  else {
            return "footer-menu shadow-up";
        }
    }
    return (
        <> 
            <div className={classNameMenu(props.is_home)}>
                 <ul className="menu-list">
                    {
                        menuLinks.map((menu, index) => {
                            return (
                                <li className="menu-item" key={index}>
                                    <Link to={menu.path}>
                                        <img  alt="" src={menu.icon} className="img-title-menu" />
                                        <span className="title-menu">{menu.name2}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>  
        </>
    );
}

export default FooterMenu