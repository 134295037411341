import React from 'react'

import logo_fema from "../../assets/images/Logo_fema_ALTA-01.png";
import logo_thermomix from "../../assets/images/logo-1@2x.png";
import logo_vorwerk from "../../assets/images/logo-vorwerk@2x.png";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="row row-cs justify-content-center align-items-center 
                subfooter">
                <div className="col-1 text-center">
                    <img alt="" src={logo_fema} className="img-fluid"></img>
                </div>
                <div className="col-1 text-center">
                    <img alt="" src={logo_thermomix} className="img-fluid"></img>
                </div>
                <div className="col-1 text-center">
                    <img alt="" src={logo_vorwerk} className="img-fluid"></img>
                </div>
            </div>

            <div className="container container-cs">
                <div className="copyright">
                    <div className="row">
                        <div className="col-6">
                            Thermomix ® 2021
                        </div>
                        <div className="col-6 text-right">
                            Desarrollado por GUX
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
